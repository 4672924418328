<template>
	<div>
		<Header title='中奖记录'></Header>
		<div class="wrap">
			<div class="giftChild flex align-center space-between" v-for="(item,index) in giftList" :key='index'>
				<div>
					<div :class="item.status==2?'commonWz9':'commonWz'">{{item.prize_name}}</div>
					<div style="color: #999999;font-size: 12px;margin-top: 5px;">{{item.create_time}}</div>
				</div>
				<div>
					<div v-if="item.status==2" class="flex align-center">
						<div style="color: #999999;font-size: 12px;margin-right: 10px;">已领取</div>
						<div class="detailBtn" @click="goDetail(item)">查看详情</div>
					</div>
					<div v-else class="btn" @click="goGift(item,index)">
						去领取
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		myPrize
	} from '../../api/home.js'
	import {
		userAddress
	} from '../../api/mine.js'
	import Header from '@/components/header.vue'
	export default {
		name: 'giftHistory',
		data() {
			return {
				giftList: [],
				token: window.localStorage.getItem('token'),
				noAddress: true
			}
		},
		components: {
			Header
		},
		created() {
			this.getMyGift()
			this.getAddress()
		},
		methods: {
			getMyGift() {
				let data = new FormData()
				data.append('token', this.token)
				myPrize(data).then(res => {
					console.log('我获得的奖品', res)
					this.giftList = res.data.data
				})
			},
			goDetail(item) {
				this.$router.push({
					path: '/giftReceive',
					query: {
						id: item.id,
						type: 1
					}
				})
			},
			goGift(item) {
				if (this.noAddress) {
					this.$router.push({
						path: '/fillInAddress',
						query: {
							id: item.id
						}
					})
				} else {
					this.$router.push({
						path: '/giftReceive',
						query: {
							id: item.id
						}
					})
				}
			},
			getAddress() {
				let data = new FormData()
				data.append('token', this.token)
				userAddress(data).then(res => {
					console.log('领奖地址', res)
					if (res.data == null) {
						this.noAddress = true
					} else {
						this.noAddress = false
					}
				})
			},
		}
	}
</script>

<style scoped>
	.flex {
		display: flex;
	}

	.align-center {
		align-items: center;
	}

	.wrap {
		background: #FFFFFF;
		box-shadow: 0px 2px 8px 0px rgba(192, 191, 191, 0.5);
		border-radius: 8px;
		margin: 13px 15px 0 15px;
		padding: 16px 14px;
		box-sizing: border-box;
	}

	.giftChild {
		height: 60px;
		width: 100%;
		border-bottom: 1px solid #E7E6E6;
	}

	.commonWz {
		color: #434444;
		font-size: 12px;
		font-weight: bold;
	}

	.detailBtn {
		width: 61px;
		height: 23px;
		background: #FFE5E7;
		border-radius: 12px;
		color: #DD1C25;
		text-align: center;
		line-height: 23px;
		letter-spacing: 1px;
	}

	.btn {
		width: 61px;
		height: 23px;
		background: #DD1C25;
		border-radius: 12px;
		color: #fff;
		text-align: center;
		line-height: 23px;
		letter-spacing: 1px;
	}

	.commonWz9 {
		color: #999;
		font-size: 12px;
		font-weight: bold;
	}
</style>
